<template>
    <div>
        <Layout>
            <div class="containerx" v-if="isLoading">
                <b-spinner label="Loading..."></b-spinner>
            </div>
            <div class="containerx" v-else>
                <h5 class="mb-4">
                    Clique no botão abaixo para acessar o seu simulado.
                </h5>
                <b-button @click="getSSOLink" variant="primary" :disabled="isLoading">
                    {{ !isLoading ? "Acessar simulado" : "Carregando..." }}
                </b-button>
            </div>
        </Layout>
    </div>
</template>

<script>
import Layout from "@/layout/Layout.vue"
import EvolucionalService from '@/services/resources/EvolucionalService.js'

const evolucional = EvolucionalService.build();

export default {
    components: {
        Layout
    },
    data() {
        return {
            iframeUrl: false,
            isLoading: false,
        }
    },
    methods: {
        openNewTab() {
            window.open(this.iframeUrl, '_blank');
        },
        async getSSOLink() {
            this.isLoading = true;
            evolucional.read('/sso').then((data) => {
                console.log(data)
                const { url } = data;
                this.iframeUrl = url;
                this.openNewTab();
            }).finally(() => {
                this.isLoading = false;
            })
        },

    },
}
</script>

<style scoped>
.containerx {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}
</style>